<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <p>Seleccionar Patrocinador</p>
            </div>
            <div class="col-md-3 mb-2">
                <input type="number" v-model="search_code" class="form-control" placeholder="Buscar código...">
            </div>
            <div class="col-md-6">
                <select class="form-control" v-model="user.sponsor_user">
                    <option value="" selected disabled v-if="users.length <= 0 && search_code">Usuario no encontrado</option>
                    <option v-for="user in users" :key="user.id" :value="user.id">{{user.id +' | '+ user.name + ' '}}</option>
                </select>
            </div>
        </div>
        <hr style="color: var(--color-dark)">
        <form @submit.prevent="validateRegisterUser(user)">
            <div class="row mt-2 mb-1">
                <div class="col-md-12">
                    <p>Documento</p>
                    <input type="number" class="form-control" v-model="user.id_card">
                </div>
            </div>
            <div class="row mt-2 mb-1">
                <div class="col-md-6">
                    <p>Nombres</p>
                    <input type="text" id="name" class="form-control" v-model="user.name">
                </div>
                <div class="col-md-6">
                    <p>Apellidos</p>
                    <input type="text" id="last_name" class="form-control" v-model="user.last_name">
                </div>
            </div>
            <div class="row mt-2 mb-1">
                <div class="col-md-12">
                    <p>WhatsApp</p>
                    <input type="number" class="form-control" v-model="user.whatsapp">
                </div>
            </div>
            <div class="row mt-2 mb-1">
                <div class="col-md-6">
                    <p>Teléfono de Contacto</p>
                    <input type="text" id="primary_phone" class="form-control" v-model="user.primary_phone">
                </div>
                <div class="col-md-6">
                    <p>Teléfono Secundario (opcional)</p>
                    <input type="text" class="form-control" v-model="user.secondary_phone">
                </div>
            </div>
            <div class="row mt-2 mb-1">
                <div class="col-md-6">
                    <p>País</p>
                    <select class="form-control" v-model="user.country" @change="filterDepartments(user.country)">
                        <option v-for="country in countries" :key="country.code" :value="country.code" >{{country.name}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <p>Departamento</p>
                    <select class="form-control" v-model="user.department" @change="filterCitiesFunction(user.department)">
                        <option value="" disabled>Selecciona una opción</option>
                        <option v-for="(deparment, index) in departments" :key="'a'+index">{{deparment.name}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <p>Ciudad</p>
                    <select class="form-control" v-model="user.city_id">
                        <option value="" disabled>Selecciona una opción</option>
                        <option v-for="city in cities_department" :key="city.id" :value="city.id" >{{city.name}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <p>Dirección, barrio o localidad (opcional)</p>
                    <input type="text" class="form-control" v-model="user.adress">
                </div>
            </div>
            <hr style="color: var(--color-dark)">
            <div class="row mt-2">
                <div class="col-md-12">
                    <p>Correo</p>
                    <input type="email" class="form-control" v-model="user.email">
                </div>
                <div class="col-md-6">
                    <p>Contraseña</p>
                    <input type="password" class="form-control" v-model="user.password">
                </div>
                <div class="col-md-6">
                    <p>Confirmar Contraseña</p>
                    <input type="password" class="form-control" v-model="user.password_confirmation">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12 mb-4">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input"
                        v-model="check_contract"
                        id="permmissions-check">
                        <label class="custom-control-label" for="permmissions-check">
                            <b>He leído y estoy de acuerdo con los <a href="#" @click="showModalTerms()" class="link">términos y condiciones</a></b>
                        </label>
                    </div>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-4">
                    <button class="btn btn-primary" style="width: 100%">Registrar</button>
                </div>
            </div>
        </form>
        <!--------TERMINOS Y CONDICIONES-------->
        <div class="modal fade" id="modal-terms" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog" style="max-width: 650px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title"><p>Autorización para tratamiento de datos personales</p></h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="max-height: 500px; overflow-y: scroll;">
                        <p>De conformidad con lo definido por la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013, la Circular Externa 002 de 2015 expedida por la Superintendencia de Industria y Comercio, la política interna de manejo de la información implementada por la sociedad COMERCIALIZADORA MULTINIVEL 3B S.A.S, sociedad legalmente constituida por documento privado del 13 de octubre de 2020, inscrito en el registro mercantil de la Cámara de Comercio de Putumayo el día 14 de octubre de 2020 con el número 9548 del libro IX, con domicilio en Puerto Guzmán, Putumayo, con NIT. 901422267-9, y las demás normas concordantes, a través de las cuales se establecen disposiciones generales en materia de hábeas data y se regula el tratamiento de la información que contenga datos personales, me permito declarar de manera expresa que:</p>
                        <p>PRIMERO. Autorizo de manera libre, voluntaria, previa, explícita, informada e inequívoca a la sociedad COMERCIALIZADORA MULTINIVEL 3B S.A.S, para que en los términos legalmente establecidos realice la recolección, almacenamiento, uso, circulación, supresión y en general, el tratamiento de los datos personales que he procedido a entregar o que entregaré, en virtud de las relaciones legales, contractuales, comerciales y/o de cualquier otra índole que surja, en desarrollo y ejecución de los fines descritos en el presente documento.</p>
                        <p>SEGUNDO. Dicha autorización para adelantar el tratamiento de mis datos personales, se extiende durante la totalidad del tiempo en el que pueda llegar consolidarse un vínculo o este persista por cualquier circunstancia con la sociedad COMERCIALIZADORA MULTINIVEL 3B S.A.S y con posterioridad al fenecimiento del mismo, siempre que tal tratamiento se encuentre relacionado con las finalidades para las cuales los datos personales, fueron inicialmente suministrados.</p>
                        <p>TERCERO. En ese sentido, declaro conocer que los datos personales objeto de tratamiento, serán utilizados específicamente para las finalidades derivadas como VENDEDOR INDEPENDIENTE.</p>
                        <p>CUARTO. Der igual forma, declaro que me han sido informados y conozco los derechos que el ordenamiento legal y la jurisprudencia, conceden al titular de los datos personales y que incluyen entre otras prerrogativas las que a continuación se relacionan: 1.) Conocer, actualizar y rectificar datos personales frente a los responsables o encargados del tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado, 2.) solicitar prueba de la autorización otorgada al responsable del tratamiento salvo cuando expresamente se exceptúe como requisito para el tratamiento, 3.) ser informado por el responsable del tratamiento o el encargado del tratamiento, previa solicitud, respecto del uso que le ha dado a mis datos personales, 4.) presentar ante la Superintendencia de Industria y Comercio quejas por infracciones al régimen de protección de datos personales, 5.) revocar la autorización y/o solicitar la supresión del dato personal cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales y 6.) acceder en forma gratuita a mis datos personales que hayan sido objeto de Tratamiento.</p>
                        <p>QUINTO. La política de manejo de datos personales adoptada por la sociedad  COMERCIALIZADORA MULTINIVEL 3B S.A.S, se encuentran en la página web.</p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" @click="confirmTerms()">Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import axios from 'axios';
export default {
    props: ['response'],
    data: function(){
        return {
            check_contract: false,
            search_code: '',
            selected_code: '',
            user : {
                name: '',
                last_name: '',
                id_card: '',
                primary_phone: '',
                secondary_phone: '',
                whatsapp: '',
                city_id: '',
                department: '',
                country: 'COL',
                email: '',
                adress: '',
                password: '',
                password_confirmation: '',
                sponsor_user: '1'
            },
            users: [],
        }
    },
    methods: {
        showModalTerms(){
            $('#modal-terms').modal('show');
        },
        confirmTerms(){
            $('#modal-terms').modal('hide');
        },
        clearFields(){
            this.user.name = '';
            this.user.last_name = '';
            this.user.id_card = '';
            this.user.primary_phone = '';
            this.user.secondary_phone = '';
            this.user.whatsapp = '';
            this.user.city_id = '';
            this.user.department = '';
            this.user.country = 'COL';
            this.user.email = '';
            this.user.adress = '';
            this.user.password = '';
            this.user.password_confirmation = '';
            this.user.sponsor_user = '1';
            this.searchUser({search: '', order: 'DESC', limit: 20});
        },
        searchUser(search){
            let url = this.api_url+'/api/search/public/users';
            axios.post(url, search, 
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                this.users = res.data;
                if(res.data.length > 0) this.user.sponsor_user = res.data[0].id;
                else this.user.sponsor_user = '';
            }).catch(e=> console.log(e.response))
        },
        filterDepartments(code){
            this.user.department = '';
            this.user.city_id = '';
            this.getCountryCities(code);
        },
        validateRegisterUser(data){
            if(!this.isValid(data.name)){
                document.getElementById('name').focus();
                return this.launchAlert({type: 'warning', title: 'El nonbre no puede contener numeros'})
            }
            if(!this.isValid(data.last_name)){
                document.getElementById('last_name').focus();
                return this.launchAlert({type: 'warning', title: 'El apellido no puede contener numeros'})
            }
            if(!data.primary_phone){
                document.getElementById('primary_phone').focus();
                return this.launchAlert({type: 'warning', title: 'Es necesario el teléfono de contacto'})
            }
            if(!this.validateEmail(data.email)){
                return this.launchAlert({type: 'warning', title: 'Digita un correo válido'})
            }
            if(!data.city_id) return this.launchAlert({type: 'warning', title: 'Selecciona una ciudad'})
            if(!data.sponsor_user) return this.launchAlert({type: 'warning', title: 'Selecciona un patrocinador'})

            var noValido = /\s/;
            if(noValido.test(data.password)) return this.launchAlert({type: 'warning', title: 'La contraseña no puede contener espacios en blanco'})
            if(data.password.length < 3) return this.launchAlert({type: 'warning', title: 'La contraseña debe tener una longitud mínima de 3 carácteres'})
            if(data.password != data.password_confirmation) return this.launchAlert({type: 'warning', title: 'Las contraseñas no coinciden'});
            if(!this.check_contract) return this.launchAlert({type: 'warning', title: '¡Es necesario aceptar términos para iniciar a ganar!'})

            this.registerUser(data).then(res => {
                if(res.data.status == 'error' && res.data.case == 'id_card') return this.launchAlert({type: 'warning', title: 'El documento actualmente se encuentra en uso'})
                if(res.data.status == 'error' && res.data.case == 'email') return this.launchAlert({type: 'warning', title: 'El correo actualmente se encuentra en uso'})
                this.clearFields();
                this.response('success');
                return this.launchAlert({type: 'success', title: 'Usuario registrado exitosamente'})
            }).catch(err => {
                console.log(err)
                console.log(err.response)
            })
        },
        launchAlert(config){
            if(!config.timeout) config.timeout = 2500;
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: config.timeout,
            })
            Toast.fire({
                icon: config.type,
                title: config.title,
                text: config.message,
            })
        },
        isValid(data){
            var regex = new RegExp("^[a-zA-ZÀ-ÿ ]+$");
            var key = data;
            if (!regex.test(key)) return false
            return true;
        },
        validateEmail(email) {
            let re = /^([\da-z_\\.-]+)@([\da-z\\.-]+)\.([a-z\\.]{2,6})$/
            if (re.test(email)) return true
            else return false
        },
        filterCitiesFunction(department){
            this.user.city_id = '';
            this.filterCities(department);
        },
        ...mapActions("user", ["registerUser"]),
        ...mapMutations("country", ["getAllCountries"]),
        ...mapMutations("country", ["getCountryCities"]),
        ...mapMutations("country", ["filterCities"]),
    },
    mounted: function(){
        this.searchUser({search: '', order: 'DESC', limit: 20});
        this.getAllCountries();
        this.getCountryCities('COL');
    },
    computed: {
        ...mapState(["api_url"]),
        ...mapState("country", ["countries"]),
        ...mapState("country", ["departments"]),
        ...mapState("country", ["cities_department"]),
    },
    watch: {
        search_code: function (){
            this.searchUser({search : this.search_code, order: 'ASC', limit: 20});
        },
        check_contract: function (){
            if(this.check_contract) return this.showModalTerms();
        }
    
    }
    
}
</script>
<style>
a.link{
    color: #007bff;
}
#modal-terms{
    background: rgba(0, 0, 0, .2);
}
</style>